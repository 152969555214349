header.Header {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    margin-bottom: 0;
    padding-top: 2rem;
    padding-bottom: 2rem;

    background-color: #fff4e4;
    box-shadow: 0 0 0 100vmax #fff4e4;
    clip-path: inset(0 -100vmax); 
}

header.Header .icon {
    width: 10rem;
    height: 10rem;
    /*background-image: url('icons/reily_floating_icon_wm.png');*/
    background-size: contain;
    border-radius: 100%;
    border: 0.125rem solid black;
}

.Header .title h1 {
    font-size: 4rem;

    margin-top: 0;
    margin-bottom: 0;
}

.Header .title {
    width: 18rem;
    margin-left: 1rem;
    text-align: center;
}

.Header .title .subtitle {
    text-align: center;
    overflow: visible;
    white-space: nowrap;
}